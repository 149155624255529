import Layout from '@/components/layout/Layout.vue';


// const Login = resolve => require(['@/views/common/login'], resolve); //登入页
const Login = resolve => require(['@/views/login/index'], resolve); //登入页
const Data = resolve => require(['@/views/data/index'], resolve); //数据中心
// const Index = resolve => require(['@/views/hicharview/index'], resolve);
const Index = resolve => require(['@/views/home/index'], resolve);



export default [{
	path: '/login',
	name: 'login',
	component: Login,
	meta: {
		requireAuth: true,
		hidden: true,
		title: '登录',
	}
},
// {
// 	path: '/data',
// 	name: 'data',
// 	component: Data,
// 	meta: {
// 		requireAuth: true,
// 		hidden: true,
// 		title: '数据中心',
// 	}
// },
{
	path: '/home',
	name: 'home',
	component: Layout,
	redirect: 'noredirect',
	meta: {
		requireAuth: true,
		title: '首页'
	},
	children: [
		{
			path: 'index',
			name: 'index',
			component: () =>
				import('@/views/home/index'),
			meta: {
				title: '首页',
				icon: 'dashboard',
				keepAlive: false
			}
		}
	]
},
// {
// 	path: '/index',
// 	name: 'index',
// 	component: import('@/views/home/index'),
// 	meta: {
// 		requireAuth: true,
// 		title: '首页'
// 	},
// },
// {
// 	path: '/login',
// 	name: 'login',
// 	component: import('@/views/login/index'),
// 	meta: {
// 		requireAuth: true,
// 		title: '登录'
// 	},
// },
{
	path: '/order',
	component: Layout,
	redirect: 'noredirect',
	children: [
		{
			path: 'order-list',
			name: 'order-list',
			component: () =>
				import('@/views/order/order-list'),
			meta: {
				title: '订单管理',
				icon: 'dashboard',
				keepAlive: false
			}
		},{
			path: 'order-detail',
			name: 'order-detail',
			component: () =>
				import('@/views/order/order-detail'),
			meta: {
				title: '订单详情',
				icon: 'dashboard',
				keepAlive: false
			}
		},{
			path: 'package-order-list',
			name: 'package-order-list',
			component: () =>
				import('@/views/order/package-order-list'),
			meta: {
				title: '套餐订单',
				icon: 'dashboard',
				keepAlive: false
			}
		},{
			path: 'package-order-info',
			name: 'package-order-info',
			component: () =>
				import('@/views/order/package-order-info'),
			meta: {
				title: '套餐订单详情',
				icon: 'dashboard',
				keepAlive: false
			}
		},{
			path: 'water-order-list',
			name: 'water-order-list',
			component: () =>
				import('@/views/order/water-order-list'),
			meta: {
				title: '取水订单',
				icon: 'dashboard',
				keepAlive: false
			}
		},{
			path: 'chargeCabinet-order-list',
			name: 'chargeCabinet-order-list',
			component: () =>
				import('@/views/order/chargeCabinet-order-list'),
			meta: {
				title: '充电柜订单',
				icon: 'dashboard',
				keepAlive: false
			}
		},{
			path: 'chargeCabinet-order-info',
			name: 'chargeCabinet-order-info',
			component: () =>
				import('@/views/order/chargeCabinet-order-info'),
			meta: {
				title: '充电柜订单详情',
				icon: 'dashboard',
				keepAlive: false
			}
		},{
			path: 'coupon-order-list',
			name: 'coupon-order-list',
			component: () =>
				import('@/views/order/coupon-order-list'),
			meta: {
				title: '优惠券订单',
				icon: 'dashboard',
				keepAlive: false
			}
		},{
			path: 'coupon-order-info',
			name: 'coupon-order-info',
			component: () =>
				import('@/views/order/coupon-order-info'),
			meta: {
				title: '优惠券订单详情',
				icon: 'dashboard',
				keepAlive: false
			}
		}
	]
},
{
	path: '/station',
	component: Layout,
	redirect: 'noredirect',
	children: [{
			path: 'station-list',
			name: 'station-list',
			component: () =>
				import('@/views/station/station-list'),
			meta: {
				title: '站点管理',
				icon: 'dashboard',
				keepAlive: false
			},
		},
			{
				path: 'station-add',
				name: 'station-add',
				component: () =>
					import('@/views/station/station-add'),
				meta: {
					title: '添加站点',
					icon: 'dashboard',
					keepAlive: true
				}
			},
			{
				path: 'station-detail',
				name: 'station-detail',
				component: () =>
					import('@/views/station/station-detail'),
				meta: {
					title: '站点详情',
					icon: 'dashboard',
					keepAlive: false
				}
			},
			{
				path: 'station-apply',
				name: 'station-apply',
				component: () =>
					import('@/views/station/station-detail-apply'),
				meta: {
					title: '站点审核',
					icon: 'dashboard',
					keepAlive:false
				},
			},
			{
				path: 'station-delivery',
				name: 'station-delivery',
				component: () =>
					import('@/views/station/station-detail-delivery'),
				meta: {
					title: '设备出库',
					icon: 'dashboard',
					keepAlive: false
				},
			},{
				path: 'station-construction',
				name: 'station-construction',
				component: () =>
					import('@/views/station/station-detail-construction'),
				meta: {
					title: '填写施工信息',
					icon: 'dashboard',
					keepAlive: true
				},
			}
            ,{
				path: 'station-label-list',
				name: 'station-label-list',
				component: () =>
					import('@/views/station/station-label-list'),
				meta: {
					title: '站点标签管理',
					icon: 'dashboard',
					keepAlive: true
				},
			}

		]
	},
	{
		path: '/price',
		component: Layout,
		redirect: 'noredirect',
		children: [{
				path: 'price-list',
				name: 'price-list',
				component: () =>
					import('@/views/price/price-list'),
				meta: {
					title: '定价管理',
					icon: 'dashboard',
					keepAlive: false
				},
			},
			{
					path: 'price-edit',
					name: 'price-edit',
					component: () =>
						import('@/views/price/price-edit'),
					meta: {
						title: '编辑定价',
						icon: 'dashboard',
						keepAlive: true
					},
				}
			]
		},
	{
		path: '/project',
		component: Layout,
		name:'project',
		redirect: 'noredirect',
		children: [
			{
				path: 'project-list',
				name: 'project-list',
				component: () =>
					import('@/views/project/project-list'),
				meta: {
					title: '项目管理',
					icon: 'dashboard',
					keepAlive: false
				},
			},
			{
				path: 'project-add',
				name: 'project-add',
				component: () =>
					import('@/views/project/project-add2'),
				meta: {
					title: '添加项目',
					icon: 'dashboard',
					keepAlive: true
				}
			},
			{
				path: 'project-detail',
				name: 'project-detail',
				component: () =>
					import('@/views/project/project-detail'),
				meta: {
					title: '项目详情',
					icon: 'dashboard',
					keepAlive: false
				}
			},
			
			{
				path: 'project-confirmed',
				name: 'project-confirmed',
				component: () =>
					import('@/views/project/project-confirmed'),
				meta: {
					title: '签署确认',
					icon: 'dashboard',
					keepAlive: false
				}
			},

		
	]
},
{
	path: '/customer',
	component: Layout,
	redirect: 'noredirect',
	children: [
		{
			path: 'customer-list',
			name: 'customer-list',
			component: () =>
				import('@/views/customer/customer-list'),
			meta: {
				title: '客户管理',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'customer-detail',
			name: 'customer-detail',
			component: () =>
				import('@/views/customer/customer-detail'),
			meta: {
				title: '客户详情',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'customer-add',
			name: 'customer-add',
			component: () =>
				import('@/views/customer/customer-add'),
			meta: {
				title: '注册客户',
				icon: 'dashboard',
				keepAlive: true
			},
		}
	]
},
{
	path: '/news',
	component: Layout,
	redirect: 'noredirect',
	children: [
		{
			path: 'news-list',
			name: 'news-list',
			component: () =>
				import('@/views/news/news-list'),
			meta: {
				title: '消息中心',
				icon: 'dashboard',
				keepAlive: false
			},
		}
	]
},
{
	path: '/userMGT',
	component: Layout,
	redirect: 'noredirect',
	children: [
		{
			path: 'userMGT-list',
			name: 'userMGT-list',
			component: () =>
				import('@/views/userMGT/userMGT-list'),
			meta: {
				title: '用户管理',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'userMGT-detail',
			name: 'userMGT-detail',
			component: () =>
				import('@/views/userMGT/userMGT-detail'),
			meta: {
				title: '用户详情',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'userMGT-complaint-list',
			name: 'userMGT-complaint-list',
			component: () =>
				import('@/views/userMGT/userMGT-complaint-list'),
			meta: {
				title: '客诉处理',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'userMGT-complaint-details',
			name: 'userMGT-complaint-details',
			component: () =>
				import('@/views/userMGT/userMGT-complaint-details'),
			meta: {
				title: '客诉处理详情',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'userMGT-whiteOrBlack-list',
			name: 'userMGT-whiteOrBlack-list',
			component: () =>
				import('@/views/userMGT/userMGT-whiteOrBlack-list'),
			meta: {
				title: '用户黑白名单列表',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'userMGT-whiteOrBlack-edit',
			name: 'userMGT-whiteOrBlack-edit',
			component: () =>
				import('@/views/userMGT/userMGT-whiteOrBlack-edit'),
			meta: {
				title: '添加/编辑用户黑白名单',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'userMGT-password-edit',
			name: 'userMGT-password-edit',
			component: () =>
				import('@/views/userMGT/userMGT-password-edit'),
			meta: {
				title: '修改密码',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'userMGT-car-audit',
			name: 'userMGT-car-audit',
			component: () =>
				import('@/views/userMGT/userMGT-car-audit'),
			meta: {
				title: '用户车辆审核列表',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'userMGT-car-audit-info',
			name: 'userMGT-car-audit-info',
			component: () =>
				import('@/views/userMGT/userMGT-car-audit-info'),
			meta: {
				title: '用户车辆审核',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'userMGT-carchange-list',
			name: 'userMGT-carchange-list',
			component: () =>
				import('@/views/userMGT/userMGT-carchange-list'),
			meta: {
				title: '用户车辆变更记录',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'userMGT-power-export',
			name: 'userMGT-power-export',
			component: () =>
				import('@/views/userMGT/userMGT-power-export'),
			meta: {
				title: '用户充电功率导出',
				icon: 'dashboard',
				keepAlive: false
			},
		}
        
	]
},
{
	path: '/goodsOrder',
	component: Layout,
	redirect: 'noredirect',
	children: [
		{
			path: 'goodsOrder-list',
			name: 'goodsOrder-list',
			component: () =>
				import('@/views/goodsOrder/goodsOrder-list'),
			meta: {
				title: '买桩记录',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'goodsOrder-detail',
			name: 'goodsOrder-detail',
			component: () =>
				import('@/views/goodsOrder/goodsOrder-detail'),
			meta: {
				title: '采购详情',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'goodsOrder-edit',
			name: 'goodsOrder-edit',
			component: () =>
				import('@/views/goodsOrder/goodsOrder-edit'),
			meta: {
				title: '采购编辑',
				icon: 'dashboard',
				keepAlive: false
			},
		}
	]
},
{
	path: '/device',
	component: Layout,
	redirect: 'noredirect',
	children: [
		{
			path: 'device-list',
			name: 'device-list',
			component: () =>
				import('@/views/device/device-list'),
			meta: {
				title: '设备列表',
				icon: 'dashboard',
				keepAlive: true,
				scrollTop: 0,
			},
		},{
			path: 'device-type-list',
			name: 'device-type-list',
			component: () =>
				import('@/views/device/device-type-list'),
			meta: {
				title: '设备类型列表',
				icon: 'dashboard',
				keepAlive: true
			},
		},{
			path: 'device-add',
			name: 'device-add',
			component: () =>
				import('@/views/device/device-add'),
			meta: {
				title: '新增设备',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'device-info',
			name: 'device-info',
			component: () =>
				import('@/views/device/device-info'),
			meta: {
				title: '设备详情',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'device-edit',
			name: 'device-edit',
			component: () =>
				import('@/views/device/device-edit'),
			meta: {
				title: '编辑设备',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'device-type-edit',
			name: 'device-type-edit',
			component: () =>
				import('@/views/device/device-type-edit'),
			meta: {
				title: '设备类型编辑',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'device-status-list',
			name: 'device-status-list',
			component: () =>
				import('@/views/device/device-status-list'),
			meta: {
				title: '设备状态列表',
				icon: 'dashboard',
				keepAlive: true
			},
		}
	]
},
{
	path: '/revenueBills',
	component: Layout,
	redirect: 'noredirect',
	children: [
		{
			path: 'revenueBills-list',
			name: 'revenueBills-list',
			component: () =>
				import('@/views/revenueBills/revenueBills-list'),
			meta: {
				title: '收益账单',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'revenueBills-details',
			name: 'revenueBills-details',
			component: () =>
				import('@/views/revenueBills/revenueBills-details'),
			meta: {
				title: '账单明细',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'revenueBills-orderList',
			name: 'revenueBills-orderList',
			component: () =>
				import('@/views/revenueBills/revenueBills-orderList'),
			meta: {
				title: '订单列表',
				icon: 'dashboard',
				keepAlive: false
			},
		}
	]
},

{
	path: '/finance',
	component: Layout,
	redirect: 'noredirect',
	children: [
		{
			path: 'finance-szls-list',
			name: 'finance-szls-list',
			component: () =>
				import('@/views/finance/finance-szls-list'),
			meta: {
				title: '收支流水',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'finance-syrz-list',
			name: 'finance-syrz-list',
			component: () =>
				import('@/views/finance/finance-syrz-list'),
			meta: {
				title: '收益对账',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'finance-syrz-details',
			name: 'finance-syrz-details',
			component: () =>
				import('@/views/finance/finance-syrz-details'),
			meta: {
				title: '收益对账详情',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'finance-package-list',
			name: 'finance-package-list',
			component: () =>
				import('@/views/finance/finance-package-list'),
			meta: {
				title: '套餐账单',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'finance-package-details',
			name: 'finance-package-details',
			component: () =>
				import('@/views/finance/finance-package-details'),
			meta: {
				title: '套餐账单详情',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'finance-zq-list',
			name: 'finance-zq-list',
			component: () =>
				import('@/views/finance/finance-zq-list'),
			meta: {
				title: '账期管理',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'finance-zq-details',
			name: 'finance-zq-details',
			component: () =>
				import('@/views/finance/finance-zq-details'),
			meta: {
				title: '账期详情',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'finance-company-list',
			name: 'finance-company-list',
			component: () =>
				import('@/views/finance/finance-company-list'),
			meta: {
				title: '商户套餐对账',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'finance-company-details',
			name: 'finance-company-details',
			component: () =>
				import('@/views/finance/finance-company-details'),
			meta: {
				title: '商户套餐对账详情',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'finance-repayment',
			name: 'finance-repayment',
			component: () =>
				import('@/views/finance/finance-repayment'),
			meta: {
				title: '商户分期',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'finance-repayment-details',
			name: 'finance-repayment-details',
			component: () =>
				import('@/views/finance/finance-repayment-details'),
			meta: {
				title: '还款详情',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'finance-repayment-dev-details',
			name: 'finance-repayment-dev-details',
			component: () =>
				import('@/views/finance/finance-repayment-dev-details'),
			meta: {
				title: '设备还款明细',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'invoice-list',
			name: 'invoice-list',
			component: () =>
				import('@/views/finance/invoice-list'),
			meta: {
				title: '发票助手',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'finance-data-panel',
			name: 'finance-data-panel',
			component: () =>
				import('@/views/finance/finance-data-panel'),
			meta: {
				title: '财务数据看板',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'finance-accounting-process',
			name: 'finance-accounting-process',
			component: () =>
				import('@/views/finance/finance-accounting-process'),
			meta: {
				title: '财务扎帐',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'finance-accounting-process-details',
			name: 'finance-accounting-process-details',
			component: () =>
				import('@/views/finance/finance-accounting-process-details'),
			meta: {
				title: '财务扎帐详情',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'finance-reconciliation',
			name: 'finance-reconciliation',
			component: () =>
				import('@/views/finance/finance-reconciliation'),
			meta: {
				title: '财务对账列表',
				icon: 'dashboard',
				keepAlive: true
			},
		},{
			path: 'finance-reconciliation-jd',
			name: 'finance-reconciliation-jd',
			component: () =>
				import('@/views/finance/finance-reconciliation-jd'),
			meta: {
				title: '玖电钱包对账列表',
				icon: 'dashboard',
				keepAlive: true
			},
		},{
			path: 'finance-reconciliation-dl',
			name: 'finance-reconciliation-dl',
			component: () =>
				import('@/views/finance/finance-reconciliation-dl'),
			meta: {
				title: '独立钱包对账列表',
				icon: 'dashboard',
				keepAlive: true
			},
		}
	]
},

{
	path: '/businessAnalysis',
	component: Layout,
	redirect: 'noredirect',
	children: [
		{
			path: 'businessAnalysis-list',
			name: 'businessAnalysis-list',
			component: () =>
				import('@/views/businessAnalysis/businessAnalysis-list'),
			meta: {
				title: '省份经营数据',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'businessAnalysis-list-city',
			name: 'businessAnalysis-list-city',
			component: () =>
				import('@/views/businessAnalysis/businessAnalysis-list-city'),
			meta: {
				title: '城市经营数据',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'businessAnalysis-list-business',
			name: 'businessAnalysis-list-business',
			component: () =>
				import('@/views/businessAnalysis/businessAnalysis-list-business'),
			meta: {
				title: '商户经营数据',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'businessAnalysis-list-project',
			name: 'businessAnalysis-list-project',
			component: () =>
				import('@/views/businessAnalysis/businessAnalysis-list-project'),
			meta: {
				title: '项目经营数据',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'businessAnalysis-list-station',
			name: 'businessAnalysis-list-station',
			component: () =>
				import('@/views/businessAnalysis/businessAnalysis-list-station'),
			meta: {
				title: '站点经营数据',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'businessAnalysis-info',
			name: 'businessAnalysis-info',
			component: () =>
				import('@/views/businessAnalysis/businessAnalysis-info'),
			meta: {
				title: '经营分析详情',
				icon: 'dashboard',
				keepAlive: false
			},
		}
	]
},
{
	path: '/sceneCheck',
	component: Layout,
	redirect: 'noredirect',
	children: [
		{
			path: 'sceneCheck-list',
			name: 'sceneCheck-list',
			component: () =>
				import('@/views/sceneCheck/sceneCheck-list'),
			meta: {
				title: '现勘列表',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'sceneCheck-info',
			name: 'sceneCheck-info',
			component: () =>
				import('@/views/sceneCheck/sceneCheck-info'),
			meta: {
				title: '现勘详情',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'sceneCheck-site-info',
			name: 'sceneCheck-site-info',
			component: () =>
				import('@/views/sceneCheck/sceneCheck-site-info'),
			meta: {
				title: '站点详情',
				icon: 'dashboard',
				keepAlive: false
			},
		}
	]
},
{
	path: '/businessCenter',
	component: Layout,
	redirect: 'noredirect',
	children: [
		{
			path: 'business-center',
			name: 'business-center',
			component: () =>
				import('@/views/businessCenter/business-center'),
			meta: {
				title: '商户中心',
				icon: 'dashboard',
				keepAlive: true
			},
		},{
			path: 'switch-business',
			name: 'switch-business',
			component: () =>
				import('@/views/businessCenter/switch-business'),
			meta: {
				title: '切换商户',
				icon: 'dashboard',
				keepAlive: true
			},
		},{
			path: 'withdrawal',
			name: 'withdrawal',
			component: () =>
				import('@/views/businessCenter/withdrawal'),
			meta: {
				title: '提现',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'withdrawal-success',
			name: 'withdrawal-success',
			component: () =>
				import('@/views/businessCenter/withdrawal-success'),
			meta: {
				title: '提现申请成功',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'bank-card-add',
			name: 'bank-card-add',
			component: () =>
				import('@/views/businessCenter/bank-card-add'),
			meta: {
				title: '添加银行卡',
				icon: 'dashboard',
				keepAlive: true
			},
		},{
			path: 'bank-xy',
			name: 'bank-xy',
			component: () =>
				import('@/views/businessCenter/bank-xy'),
			meta: {
				title: '用户服务协议及隐私政策',
				icon: 'dashboard',
				keepAlive: false
			},
		}
	]
},
{
	path: '/staffManage',
	component: Layout,
	redirect: 'noredirect',
	children: [
		{
			path: 'staff-manage',
			name: 'staff-manage',
			component: () =>
				import('@/views/staffManage/staff-manage'),
			meta: {
				title: '员工管理',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'staff-detail',
			name: 'staff-detail',
			component: () =>
				import('@/views/staffManage/staff-detail'),
			meta: {
				title: '员工详情',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'staff-add',
			name: 'staff-add',
			component: () =>
				import('@/views/staffManage/staff-add'),
			meta: {
				title: '新建员工',
				icon: 'dashboard',
				keepAlive: true
			}
		},{
			path: 'staff-exit',
			name: 'staff-exit',
			component: () =>
				import('@/views/staffManage/staff-exit'),
			meta: {
				title: '编辑员工',
				icon: 'dashboard',
				keepAlive: true
			}
		},{
			path: 'staff-role-edit',
			name: 'staff-role-edit',
			component: () =>
				import('@/views/staffManage/staff-role-edit'),
			meta: {
				title: '编辑角色',
				icon: 'dashboard',
				keepAlive: true
			}
		}
        ,{
			path: 'staff-role-manage',
			name: 'staff-role-manage',
			component: () =>
				import('@/views/staffManage/staff-role-manage'),
			meta: {
				title: '角色管理',
				icon: 'dashboard',
				keepAlive: true
			}
		}
        ,{
			path: 'staff-role-details',
			name: 'staff-role-details',
			component: () =>
				import('@/views/staffManage/staff-role-details'),
			meta: {
				title: '角色详情',
				icon: 'dashboard',
				keepAlive: true
			}
		}	
    ]
},{
	path: '/withdrawal',
	component: Layout,
	redirect: 'noredirect',
	children: [
		{
			path: 'withdrawal-view',
			name: 'withdrawal-view',
			component: () =>
				import('@/views/withdrawal/withdrawal-view'),
			meta: {
				title: '提现管理',
				icon: 'dashboard',
				keepAlive: false
			},
		},{
			path: 'withdrawal-detail',
			name: 'withdrawal-detail',
			component: () =>
				import('@/views/withdrawal/withdrawal-detail'),
			meta: {
				title: '提现详情',
				icon: 'dashboard',
				keepAlive: false
			},
		}
	]
},{
	path: '/test',
	component: Layout,
	redirect: 'noredirect',
	children: [{
		path: 'user',
		name: 'user',
		component: () =>
			import('@/views/hichartable/user'),
		meta: {
			title: '样例页面',
			icon: 'dashboard',
			keepAlive: false
		}
	}
	]
},
{
	path: '/chargeCard',
	component: Layout,
	redirect: 'noredirect',
	children: [{
		path: 'chargeCard-list',
		name: 'chargeCard-list',
		component: () =>
			import('@/views/chargeCard/chargeCard-list'),
		meta: {
			title: '充电卡管理',
			icon: 'dashboard',
			keepAlive: false
		}
	},
	{
		path: 'chargeCard-onLine',
		name: 'chargeCard-onLine',
		component: () =>
			import('@/views/chargeCard/chargeCard-onLine'),
		meta: {
			title: '在线卡管理',
			icon: 'dashboard',
			keepAlive: false
		}
	},
	{
		path: 'chargeCard-offLine',
		name: 'chargeCard-offLine',
		component: () =>
			import('@/views/chargeCard/chargeCard-offLine'),
		meta: {
			title: '离线卡管理',
			icon: 'dashboard',
			keepAlive: false
		}
	}
	]
},
{
	path: '/todolist',
	component: Layout,
	redirect: 'noredirect',
	children: [{
		path: 'todolist-index',
		name: 'todolist-index',
		component: () =>
			import('@/views/todolist/todolist-index'),
		meta: {
			title: '待办事项',
			icon: 'dashboard',
			keepAlive: false
		}
	}
	]
},
// 加盟商管理
{
	path:'/franchisee',
	component: Layout,
	redirect: 'noredirect',
	children:[{
		path: 'franchisee-view',
		name: 'franchisee-view',
		component: () =>import('@/views/franchisee/franchisee-view'),
		meta: {
			title: '加盟商管理',
			icon: 'dashboard',
			keepAlive: false
		},
	},{
		path: 'franchisee-detail',
		name: 'franchisee-detail',
		component: () =>import('@/views/franchisee/franchisee-detail'),
		meta: {
			title: '加盟商详情',
			icon: 'dashboard',
			keepAlive: false
		},
	},{
		path: 'franchisee-add',
		name: 'franchisee-add',
		component: () =>import('@/views/franchisee/franchisee-add'),
		meta: {
			title: '新增加盟商',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'franchisee-change',
		name: 'franchisee-change',
		component: () =>import('@/views/franchisee/franchisee-change'),
		meta: {
			title: '变更加盟合同',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'franchisee-contract',
		name: 'franchisee-contract',
		component: () =>import('@/views/franchisee/franchisee-contract'),
		meta: {
			title: '加盟商详情',
			icon: 'dashboard',
			keepAlive: false
		},
	},{
		path: 'franchisee-join',
		name: 'franchisee-join',
		component: () =>import('@/views/franchisee/franchisee-join'),
		meta: {
			title: '合作加盟',
			icon: 'dashboard',
			keepAlive: false
		},
	}]
},
// 加盟商进件管理
{
	path:'/companyUpgrade',
	component: Layout,
	redirect: 'noredirect',
	children:[{
		path: 'companyUpgrade-list',
		name: 'companyUpgrade-list',
		component: () =>import('@/views/companyUpgrade/companyUpgrade-list'),
		meta: {
			title: '加盟商进件管理',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'companyUpgrade-edit',
		name: 'companyUpgrade-edit',
		component: () =>import('@/views/companyUpgrade/companyUpgrade-edit'),
		meta: {
			title: '加盟商微信进件',
			icon: 'dashboard',
			keepAlive: false
		},
	},{
		path: 'companyUpgrade-bf-edit',
		name: 'companyUpgrade-bf-edit',
		component: () =>import('@/views/companyUpgrade/companyUpgrade-bf-edit'),
		meta: {
			title: '加盟商宝付进件',
			icon: 'dashboard',
			keepAlive: false
		},
	}]
},
// 月卡管理
{
	path:'/monthCard',
	component: Layout,
	redirect: 'noredirect',
	children:[{
		path: 'monthCard-list',
		name: 'monthCard-list',
		component: () =>import('@/views/monthCard/monthCard-list'),
		meta: {
			title: '月卡列表',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'monthCard-relation',
		name: 'monthCard-relation',
		component: () =>import('@/views/monthCard/monthCard-relation'),
		meta: {
			title: '月卡关联管理',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'monthCard-edit',
		name: 'monthCard-edit',
		component: () =>import('@/views/monthCard/monthCard-edit'),
		meta: {
			title: '月卡编辑',
			icon: 'dashboard',
			keepAlive: false
		},
	},{
		path: 'monthCard-batch-edit',
		name: 'monthCard-batch-edit',
		component: () =>import('@/views/monthCard/monthCard-batch-edit'),
		meta: {
			title: '月卡批量添加',
			icon: 'dashboard',
			keepAlive: false
		},
	},{
		path: 'monthCard-storedValue',
		name: 'monthCard-storedValue',
		component: () =>import('@/views/monthCard/monthCard-storedValue'),
		meta: {
			title: '储值卡列表',
			icon: 'dashboard',
			keepAlive: false
		},
	},{
		path: 'monthCard-storedValue-batch',
		name: 'monthCard-storedValue-batch',
		component: () =>import('@/views/monthCard/monthCard-storedValue-batch'),
		meta: {
			title: '储值卡批量添加',
			icon: 'dashboard',
			keepAlive: false
		},
	},{
		path: 'monthCard-temp-list',
		name: 'monthCard-temp-list',
		component: () =>import('@/views/monthCard/monthCard-temp-list'),
		meta: {
			title: '月卡模版管理',
			icon: 'dashboard',
			keepAlive: true
		},
	}]
},
// 导出管理
{
	path:'/export',
	component: Layout,
	redirect: 'noredirect',
	children:[{
		path: 'export-list',
		name: 'export-list',
		component: () =>import('@/views/export/export-list'),
		meta: {
			title: '导出列表',
			icon: 'dashboard',
			keepAlive: true
		},
	}]
},
// 人员管理
{
	path:'/personnel',
	component: Layout,
	redirect: 'noredirect',
	children:[{
		path: 'rfid-audit-list',
		name: 'rfid-audit-list',
		component: () =>import('@/views/personnel/rfid-audit-list'),
		meta: {
			title: '人脸审核',
			icon: 'dashboard',
			keepAlive: true
		},
	}]
},
// 活动管理
{
	path:'/activity',
	component: Layout,
	redirect: 'noredirect',
	children:[{
		path: 'activity-list',
		name: 'activity-list',
		component: () =>import('@/views/activity/activity-list'),
		meta: {
			title: '活动列表',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'activity-edit',
		name: 'activity-edit',
		component: () =>import('@/views/activity/activity-edit'),
		meta: {
			title: '活动编辑',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'coupon-list',
		name: 'coupon-list',
		component: () =>import('@/views/activity/coupon-list'),
		meta: {
			title: '优惠券列表',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'coupon-edit',
		name: 'coupon-edit',
		component: () =>import('@/views/activity/coupon-edit'),
		meta: {
			title: '优惠券添加/编辑',
			icon: 'dashboard',
			keepAlive: false
		},
	},{
		path: 'coupon-user-list',
		name: 'coupon-user-list',
		component: () =>import('@/views/activity/coupon-user-list'),
		meta: {
			title: '优惠券使用列表',
			icon: 'dashboard',
			keepAlive: false
		},
	},{
		path: 'activity-user-list',
		name: 'activity-user-list',
		component: () =>import('@/views/activity/activity-user-list'),
		meta: {
			title: '活动消耗记录',
			icon: 'dashboard',
			keepAlive: false
		},
	},{
		path: 'push-list',
		name: 'push-list',
		component: () =>import('@/views/activity/push-list'),
		meta: {
			title: '推送列表',
			icon: 'dashboard',
			keepAlive: false
		},
	},{
		path: 'push-edit',
		name: 'push-edit',
		component: () =>import('@/views/activity/push-edit'),
		meta: {
			title: '编辑/新增推送',
			icon: 'dashboard',
			keepAlive: false
		},
	},{
		path: 'coupon-package-list',
		name: 'coupon-package-list',
		component: () =>import('@/views/activity/coupon-package-list'),
		meta: {
			title: '优惠券包',
			icon: 'dashboard',
			keepAlive: true
		},
	}]
},

// 预警管理
{
	path:'/warning',
	component: Layout,
	redirect: 'noredirect',
	children:[{
		path: 'warning-list',
		name: 'warning-list',
		component: () =>import('@/views/warning/warning-list'),
		meta: {
			title: '预警列表',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'warning-manage-list',
		name: 'warning-manage-list',
		component: () =>import('@/views/warning/warning-manage-list'),
		meta: {
			title: '预警管理',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'warning-manage-edit',
		name: 'warning-manage-edit',
		component: () =>import('@/views/warning/warning-manage-edit'),
		meta: {
			title: '预警编辑',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'warning-user-list',
		name: 'warning-user-list',
		component: () =>import('@/views/warning/warning-user-list'),
		meta: {
			title: '预警人员管理',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'warning-user-edit',
		name: 'warning-user-edit',
		component: () =>import('@/views/warning/warning-user-edit'),
		meta: {
			title: '预警人员编辑',
			icon: 'dashboard',
			keepAlive: true
		},
	}]
},

// 观察管理
{
	path:'/observe',
	component: Layout,
	redirect: 'noredirect',
	children:[{
		path: 'observe-list',
		name: 'observe-list',
		component: () =>import('@/views/observe/observe-list'),
		meta: {
			title: '观察列表',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'observe-station-list',
		name: 'observe-station-list',
		component: () =>import('@/views/observe/observe-station-list'),
		meta: {
			title: '观察站点列表',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'observe-chart',
		name: 'observe-chart',
		component: () =>import('@/views/observe/observe-chart'),
		meta: {
			title: '观察看板分析',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'observe-edit',
		name: 'observe-edit',
		component: () =>import('@/views/observe/observe-edit'),
		meta: {
			title: '观察编辑',
			icon: 'dashboard',
			keepAlive: true
		},
	}]
},

// 电费管理
{
	path:'/electric',
	component: Layout,
	redirect: 'noredirect',
	children:[{
		path: 'electric-list',
		name: 'electric-list',
		component: () =>import('@/views/electric/electric-list'),
		meta: {
			title: '电费项目列表',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'electric-edit',
		name: 'electric-edit',
		component: () =>import('@/views/electric/electric-edit'),
		meta: {
			title: '添加/编辑电费项目',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'electric-info',
		name: 'electric-info',
		component: () =>import('@/views/electric/electric-info'),
		meta: {
			title: '电费项目详情',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'electric-property-list',
		name: 'electric-property-list',
		component: () =>import('@/views/electric/electric-property-list'),
		meta: {
			title: '物业管理列表',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'electric-property-edit',
		name: 'electric-property-edit',
		component: () =>import('@/views/electric/electric-property-edit'),
		meta: {
			title: '添加/编辑物业',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'electric-charge-list',
		name: 'electric-charge-list',
		component: () =>import('@/views/electric/electric-charge-list'),
		meta: {
			title: '自缴电费管理',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'electric-charge-chart',
		name: 'electric-charge-chart',
		component: () =>import('@/views/electric/electric-charge-chart'),
		meta: {
			title: '电费站点趋势',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'electric-charge-station',
		name: 'electric-charge-station',
		component: () =>import('@/views/electric/electric-charge-station'),
		meta: {
			title: '电费站点维度详情',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'electric-charge-bill',
		name: 'electric-charge-bill',
		component: () =>import('@/views/electric/electric-charge-bill'),
		meta: {
			title: '电费账单趋势详情',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'electric-charge-add',
		name: 'electric-charge-add',
		component: () =>import('@/views/electric/electric-charge-add'),
		meta: {
			title: '添加电费账单',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'electric-bill-list',
		name: 'electric-bill-list',
		component: () =>import('@/views/electric/electric-bill-list'),
		meta: {
			title: '电费账单列表',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'electric-bill-info',
		name: 'electric-bill-info',
		component: () =>import('@/views/electric/electric-bill-info'),
		meta: {
			title: '电费账单详情',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'electric-data-panel',
		name: 'electric-data-panel',
		component: () =>import('@/views/electric/electric-data-panel'),
		meta: {
			title: '电费数据看板',
			icon: 'dashboard',
			keepAlive: true
		},
	}]
},

// 任务
{
	path:'/task',
	component: Layout,
	redirect: 'noredirect',
	children:[{
		path: 'task-datas-list',
		name: 'task-datas-list',
		component: () =>import('@/views/task/task-datas-list'),
		meta: {
			title: '任务审核列表',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'task-datas-audit',
		name: 'task-datas-audit',
		component: () =>import('@/views/task/task-datas-audit'),
		meta: {
			title: '任务审核',
			icon: 'dashboard',
			keepAlive: true
		},
	}]
},

// 工程
{
	path:'/engineering',
	component: Layout,
	redirect: 'noredirect',
	children:[{
		path: 'engineering-list',
		name: 'engineering-list',
		component: () =>import('@/views/engineering/engineering-list'),
		meta: {
			title: '工程合同管理',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'engineering-edit',
		name: 'engineering-edit',
		component: () =>import('@/views/engineering/engineering-edit'),
		meta: {
			title: '工程合同编辑',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'engineering-income-list',
		name: 'engineering-income-list',
		component: () =>import('@/views/engineering/engineering-income-list'),
		meta: {
			title: '工程收益列表',
			icon: 'dashboard',
			keepAlive: true
		},
	}]
},

// 工单
{
	path:'/workOrder',
	component: Layout,
	redirect: 'noredirect',
	children:[{
		path: 'workOrder-list',
		name: 'workOrder-list',
		component: () =>import('@/views/workOrder/workOrder-list'),
		meta: {
			title: '工单列表',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'workOrder-edit',
		name: 'workOrder-edit',
		component: () =>import('@/views/workOrder/workOrder-edit'),
		meta: {
			title: '创建工单',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'workOrder-info',
		name: 'workOrder-info',
		component: () =>import('@/views/workOrder/workOrder-info'),
		meta: {
			title: '工单详情',
			icon: 'dashboard',
			keepAlive: true
		},
	}]
},

// 巡检
{
	path:'/inspection',
	component: Layout,
	redirect: 'noredirect',
	children:[{
		path: 'inspection-list',
		name: 'inspection-list',
		component: () =>import('@/views/inspection/inspection-list'),
		meta: {
			title: '巡检任务',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'inspection-edit',
		name: 'inspection-edit',
		component: () =>import('@/views/inspection/inspection-edit'),
		meta: {
			title: '创建巡检',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'inspection-info',
		name: 'inspection-info',
		component: () =>import('@/views/inspection/inspection-info'),
		meta: {
			title: '巡检详情',
			icon: 'dashboard',
			keepAlive: true
		},
	}]
},
// 数据看板
{
	path:'/dataPanel',
	component: Layout,
	redirect: 'noredirect',
	children:[{
		path: 'data-inspection',
		name: 'data-inspection',
		component: () =>import('@/views/dataPanel/data-inspection'),
		meta: {
			title: '巡检/工单数据看板',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'data-inspection-info',
		name: 'data-inspection-info',
		component: () =>import('@/views/dataPanel/data-inspection-info'),
		meta: {
			title: '巡检看板详情',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'data-inspection-workorder',
		name: 'data-inspection-workorder',
		component: () =>import('@/views/dataPanel/data-inspection-workorder'),
		meta: {
			title: '工单看板详情',
			icon: 'dashboard',
			keepAlive: true
		},
	},{
		path: 'data-staff-info',
		name: 'data-staff-info',
		component: () =>import('@/views/dataPanel/data-staff-info'),
		meta: {
			title: '员工效率详情',
			icon: 'dashboard',
			keepAlive: true
		},
	}]
},
// 商品
{
	path:'/goods',
	component: Layout,
	redirect: 'noredirect',
	children:[{
		path: 'goods-edit',
		name: 'goods-edit',
		component: () =>import('@/views/goods/goods-edit'),
		meta: {
			title: '商品编辑',
			icon: 'dashboard',
			keepAlive: false
		},
	}]
},

// 其他工具功能
{
	path:'/tools',
	component: Layout,
	redirect: 'noredirect',
	children:[{
		path: 'updateDevSoftware',
		name: 'updateDevSoftware',
		component: () =>import('@/views/tools/updateDevSoftware'),
		meta: {
			title: '上传设备软件包',
			icon: 'dashboard',
			keepAlive: false
		},
	},{
		path: 'station-planview-edit',
		name: 'station-planview-edit',
		component: () =>import('@/views/tools/station-planview-edit'),
		meta: {
			title: '站点平面图编辑',
			icon: 'dashboard',
			keepAlive: false
		},
	}]
},
{
	path: '/404',
	component: () =>
		import('@/views/common/404.vue'),
	hidden: true
},
{
	path: '*',
	redirect: '/home/index',
	hidden: true
},



]
